@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .btn {
        @apply rounded-[100px] flex items-center justify-center p-2.5 text-xl uppercase max-w-[305px] mx-auto font-paytoneOne
    }
    .btn-primary {
        @apply bg-[#FEB710] text-black
    }
    .btn-outlined {
        @apply text-white shadow-[inset_0_0_0_2px_#797979] bg-[#393939]
    }
    .glowing {
        @apply border border-[#FEB710] shadow-[0_0_30px_0_rgba(254,183,16,0.50)]
    }
}


body {
    @apply font-alata text-[#fff] bg-[#212121]
}
/* Header */
.page-header {
    @apply flex text-white justify-between items-center bg-[#212121] px-[8px] py-[14px] font-spaceGrotesk;
}
.header-shadow .page-header {
    @apply shadow-lg;
}
.header-balance {
    width: 191px;
    height: 31px;
    background: url(../public/imgs/bg-balance.png) no-repeat center center / cover;
}
.header-balance .item {
    padding: 5px;
}
.header-balance .item+.item {
    border-left: 1px solid #777;
}
.header-balance .num {
    color:  #E8EDEE;
    text-shadow: 0px 6px 3px rgba(255, 255, 255, 0.20);
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.98px;
    text-transform: uppercase;
    margin: 0 18px 0 10px;
}
.header-user--avatar .noti-dot {
    @apply absolute left-[-6px] top-0 w-[10px] h-[10px] rounded-full bg-[#E02B2B] z-[1]
}
.header-user--avatar .trophy {
    @apply absolute left-[-6px] bottom-0 w-[16px] h-[16px] z-[1]
}
.header-user--avatar .avatar-wrap {
    @apply w-[33px] h-[33px] rounded-full p-1 bg-[#393939] overflow-hidden relative
}
.header-user--avatar .avatar-wrap img {
    @apply absolute left-[50%] bottom-0 translate-x-[-50%]
}

/* Intro Splash Page */
.intro .title {
    @apply font-trykker text-[40px] uppercase mb-[16px]
}
.intro-content {
    @apply absolute w-full bottom-[40px] left-0 px-[20px]
}

/* CARD LIST */
.mini-card {
    transition: all .3s;
}
.mini-card:not(:first-child) {
    margin-left: -74px;
}

.card-list {
    display: flex;
    overflow-x: auto;
    padding: 16px 8px;
}
.card-list::-webkit-scrollbar {
    display: none;
}
.card-list .card-item {
    flex-shrink: 0;
}
.card-item img {
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.5);
}
.card-list > *:not(:first-child) {
    margin-left: -80px;
}

.bg-grad {
    background: linear-gradient(0deg, #393939 0%, #393939 100%), linear-gradient(180deg, #297CC8 0%, #349752 100%);
}
.bg-grad-black {
    background: linear-gradient(135deg, #13202B 0%, #271A2D 100%), #212121;
}
.bg-aura {
    background: radial-gradient(49.84% 70.99% at 50.16% 70.99%, rgba(255, 255, 255, 0.30) 0%, rgba(255, 255, 255, 0.00) 100%);
}

/* SWIPER */
.swiper-slide {
    max-width: 60%;
    padding: 25px 0;
}
.swiper-slide img {
    border: 1px solid transparent;
    transform: scale(.8);
    transition: all 0.3s ease-in-out;
    box-shadow: 0 0 10px transparent;
}
.swiper-slide.swiper-slide-active img {
    transform: scale(1);
    border-color: #FEB710;
    box-shadow: 0 0 20px #FEB710;
}
/*.swiper-slide.swiper-slide-active {*/
/*    transform: scale(1.2) !important;*/
/*}*/

/*.swiper-wrapper {*/
/*    height: 362px !important;*/
/*    padding-block: 20px;*/
/*}*/

/*.swiper-slide {*/
/*    transition: all 0.3s ease-in-out;*/
/*    width: 180px !important;*/
/*}*/

/* CARD FLIP */
.card {
    position: relative;
    width: 100%;
    height: 100%;
    cursor: pointer;
    transform-style: preserve-3d;
    transform-origin: center right;
    transition: transform 1s;
  }
  
  .card.is-flipped {
    transform: translateX(-100%) rotateY(-180deg);
  }
  
  .card-face {
    position: absolute;
    width: 100%;
    height: 100%;
    line-height: 260px;
    color: white;
    text-align: center;
    font-weight: bold;
    font-size: 40px;
    backface-visibility: hidden;
  }
  
  .card-face-front {
    background: transparent;
  }
  
  .card-face-back {
    background: transparent;
    transform: rotateY(180deg);
  }

  /* MODAL SHARE */
  .modal::after {
    content: '';
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 2;
    inset: 0;
    background: #0000008a;
  }